import React from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'
import { Home } from './components/Home'
import { Graphs } from './components/Graphs'
import { Leaderboard } from './components/Leaderboard'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/slippi/' element={<Home />} /> */}
        <Route path='/leaderboard' element={<Leaderboard />} />
        {/* <Route path='/slippi/leaderboard' element={<Home />} /> */}
        <Route path='/user/:userCode' element={<Graphs />} />
        {/* <Route path='/slippi/user/:userCode' element={<Graphs />} /> */}
      </Routes>
    </div>
  )
}

export default App
