import './Header.css'
import { useNavigate } from 'react-router-dom'

export const Header = () => {
    const navigate = useNavigate()

    const onLeaderboardClick = () => {
        navigate('/leaderboard/')
    }

    const onTitleClick = () => {
        navigate('/')
    }

    return (
        <div className='header'>
            <button className='navButton' onClick={onTitleClick}>Slippi Ranked Elo Tracker</button>
            <p>The End. This service is no longer updating, if you have any questions reach me on discord @g.arcy</p>
            <button className='navButton' onClick={onLeaderboardClick}>Leaderboard</button>
        </div>
    )
}