import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import * as Realm from 'realm-web'
import Chart from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import { Header } from './Header'
import { Footer } from './Footer'
import './Inputs.css'
import './Graphs.css'

let max = -1
let min = 3001

export const Graphs = () => {
  const { userCode } = useParams()
  const [tagTextBox, setTagTextBox] = React.useState('')
  const [eloChart, setEloChart] = React.useState(null)
  const navigate = useNavigate()

  React.useEffect(() => {
    const formatedUserCode = userCode.toUpperCase().replace('-', '#')
    if (verifyTag(formatedUserCode)) {
      buildChartFromURL(formatedUserCode)
    }
    else {
      alert('Cannot Add User to Database, wrong tag format.')
    }
  }, [])

  function verifyTag(tag) {
    const re = /^[A-Z0-9]{1,6}#[0-9]{1,3}$/
    return re.test(tag)
  }

  async function buildChartFromURL(tag) {
    const app = new Realm.App({ id: 'slippielotracker-ksklh' });
    const credentials = Realm.Credentials.anonymous();
    var result = null
    try {
      const user = await app.logIn(credentials);
      result = await user.functions.getUserData(tag)
      if (result !== null) {
        makeCharts(result)
      }
      else {
        if (verifyTag(tag)) {
          navigate('/')
          alert('Adding User to Database.')
          user.functions.addNewUser(tag)
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function addUserToChart(tag) {
    const app = new Realm.App({ id: 'slippielotracker-ksklh' });
    const credentials = Realm.Credentials.anonymous();
    var result = null
    try {
      const user = await app.logIn(credentials);
      result = await user.functions.getUserData(tag)
      if (result !== null) {
        updateChart(result)
      }
      else {
        if (verifyTag(tag)) {
          navigate('/')
          user.functions.addNewUser(tag)
          alert('Adding User to Database.')
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getRandomTag() {
    const app = new Realm.App({ id: 'slippielotracker-ksklh' });
    const credentials = Realm.Credentials.anonymous();
    var result = null
    try {
      const user = await app.logIn(credentials);
      result = await user.functions.getRandomTag()
      var biggest = null
      for (let i = 0; i < result.length; i++) {
        if (biggest === null || result[i].datapoints.length > biggest.datapoints.length) {
          biggest = result[i]
        }
      }
      setTagTextBox(biggest.tag)

    } catch (err) {
      console.error(err);
    }
  }

  function getRandomColor() {
    var letters = '56789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  function updateRankLines(chart, user) {
    var rankValues = [766, 914, 1055, 1189, 1316, 1436, 1549, 1654, 1752, 1843, 1928, 2004, 2074, 2137, 2192, 2275, 2350]
    var rankWords = ['Bronze 2', 'Bronze 3', 'Silver 1', 'Silver 2', 'Silver 3', 'Gold 1', 'Gold 2', 'Gold 3', 'Platinum 1', 'Platinum 2', 'Platinum 3', 'Diamond 1', 'Diamond 2', 'Diamond 3', 'Master 1', 'Master 2', 'Master 3']

    let userMax = Math.max(...user.datapoints)
    let userMin = Math.min(...user.datapoints)

    if (max < userMax) {
      max = userMax
    }
    if (min > userMin) {
      min = userMin
    }

    var rankLines = []
    for (let i = 0; i < rankValues.length; i++) {
      if (rankValues[i] > min - 150 && rankValues[i] < max + 150) {
        rankLines.push([rankValues[i], rankWords[i]])
      }
    }

    for (let i = 0; i < rankLines.length; i++) {
      chart.options.plugins.annotation.annotations[rankLines[i][1]] = {
        type: 'line',
        yMin: rankLines[i][0],
        yMax: rankLines[i][0],
        borderWidth: 1,
        borderColor: '#ffffff',
      }
      chart.options.plugins.annotation.annotations[rankLines[i][1] + 'label'] = {
        type: 'label',
        yValue: rankLines[i][0] + 15,
        xValue: 10,
        borderWidth: 0,
        color: '#ffffff',
        content: rankLines[i][1]
      }
    }
    chart.update()
  }

  function updateChart(user) {
    let color = getRandomColor()

    var data = []
    for (var i = 0; i < user.datapoints.length; i++) {
      data.push({
        x: (i / (user.datapoints.length - 1)) * 250,
        y: user.datapoints[i]
      })
    }

    var dataset = {
      label: user.tag,
      data: data,
      borderColor: color,
      backgroundColor: color,
      pointRadius: 2
    }

    eloChart.data.datasets.push(dataset)
    updateRankLines(eloChart, user)
  }

  function makeCharts(user) {
    if (eloChart !== null) {
      eloChart.destroy()
    }

    Chart.register(annotationPlugin)
    Chart.defaults.color = '#B4B4B4'
    Chart.defaults.borderColor = '#B4B4B4'
    const chart = new Chart('rankChart', {
      type: 'line',
      data: {
        datasets: []
      },
      options: {
        responsive: true,
        tooltips: false,
        scales: {
          x: {
            grid: {
              color: '#3d4149'
            },
            type: 'linear',
            beginAtZero: true,
            ticks: {
              stepSize: 10,
              display: false
            }
          },
          y: {
            grid: {
              color: '#3d4149'
            }
          }
        },
        plugins: {
          annotation: {
            annotations: {}
          }
        }
      }
    })

    let color = getRandomColor()

    var data = []
    for (var i = 0; i < user.datapoints.length; i++) {
      data.push({
        x: (i / (user.datapoints.length - 1)) * 250,
        y: user.datapoints[i]
      })
    }

    var dataset = {
      label: user.tag,
      data: data,
      borderColor: color,
      backgroundColor: color,
      pointRadius: 2
    }

    chart.data.datasets.push(dataset)

    if (user.peak !== undefined) {
      var peakDataset = {
        label: user.tag + ' Peak',
        data: [{
          x: 0,
          y: user.peak
        }, {
          x: 250,
          y: user.peak
        }],
        borderColor: 'red',
        backgroundColor: 'red',
        pointRadius: 0
      }

      chart.data.datasets.push(peakDataset)
    }

    updateRankLines(chart, user)
    chart.update()
    setEloChart(chart)

    var colors = []
    for (let i = 0; i < 25; i++) {
      colors.push(getRandomColor())
    }

    var characterNames = []
    var characterGames = []
    for (let i = 0; i < user.characters.length; i++) {
      characterNames.push(user.characters[i].character)
      characterGames.push(user.characters[i].gameCount)
    }

    new Chart('characterChart', {
      type: 'bar',
      data: {
        labels: characterNames,
        datasets: [{
          label: 'Character Games',
          data: characterGames,
          backgroundColor: [color, ...colors],
          borderColor: [color, ...colors]
        }]
      },
      options: {
        responsive: true,
        aspectRatio: 2,
        scales: {
          x: {
            ticks: {
              font: {
                size: 10
              },
              maxRotation: 65,
              minRotation: 65
            },
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            },
            type: 'logarithmic',
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    })

    new Chart('winsVLossesChart', {
      type: 'pie',
      data: {
        labels: ['Wins', 'Losses'],
        datasets: [{
          label: 'Wins and Losses',
          data: [user.wins, user.losses],
          backgroundColor: [color, 'red'],
          borderColor: [color, 'red']
        }]
      },
      options: {
        responsive: true,
        aspectRatio: 2
      }
    })
  }

  const onTagChange = (e) => {
    const tagUpper = e.target.value.toUpperCase();
    setTagTextBox(tagUpper)
  }

  const onAddClickHandler = () => {
    if (verifyTag(tagTextBox)) {
      addUserToChart(tagTextBox)
    }
    else {
      alert('Invalid Tag')
    }
  }

  const onBackClickHandler = () => {
    max = -1
    min = 3001
    navigate('/')
  }

  const onRandomClickHandler = () => {
    getRandomTag()
  }

  return (
    <div>
      <Header />
      <div className='charts'>
        <div className='mainChart'>
          <canvas id='rankChart'></canvas>
        </div>
        <div className='sideCharts'>
          <canvas className='characterChart' id='characterChart'></canvas>
          <canvas id='winsVLossesChart'></canvas>
        </div>
      </div>
      <div className='inputs spacing'>
        <input className='tagInput' spellCheck='false' type='text' placeholder='user#123' maxLength='8' value={tagTextBox} onChange={onTagChange}></input>
        <br />
        <button className='inputButton' onClick={onAddClickHandler}>Add</button>
        <br />
        <button className='inputButton' onClick={onRandomClickHandler}>Random</button>
        <br />
        <button className='inputButton' onClick={onBackClickHandler}>Back</button>
      </div>
      <Footer />
    </div>
  )
}