import React from 'react'
import * as Realm from 'realm-web'
import { Header } from './Header'
import './Leaderboard.css'

export const Leaderboard = () => {
  const [peakList, setPeakList] = React.useState([]);
  const [currList, setCurrList] = React.useState([]);
  const [checked, setChecked] = React.useState(false)

  React.useEffect(() => {
    getLeaderboard();
  }, [])

  async function getLeaderboard() {
    const app = new Realm.App({ id: 'slippielotracker-ksklh' });
    const credentials = Realm.Credentials.anonymous();
    var result = null
    try {
      const user = await app.logIn(credentials);
      setCurrList(await user.functions.getAllUsersInOrderByCurrent());
      setPeakList(await user.functions.getAllUsersInOrderByPeak());
    } catch (err) {
      console.error(err);
    }
  }

  const makeLeaderboardPeak = peakList.map((user, index) => 
  <li className='tableRow' key={user["tag"]}>
      <p>{index+1}</p><p>{user["tag"]}</p><p>{user["peak"]}</p>
  </li>
  )

  const makeLeaderboardCurrent = currList.map((user, index) => 
    <li className='tableRow' key={user["tag"]}>
        <p>{index+1}</p><p>{user["tag"]}</p><p>{user["datapoints"][0]}</p>
    </li>
  )

  const handleToggle = () => {
    setChecked(!checked)
    document.getElementById('curr').hidden = checked
    document.getElementById('peak').hidden = !checked
  }

  return (
    <div>
      <Header />
      <div className='toggle'>
        <p className='toggleOption'>Peak</p>
        <div className='switchAlign'>
            <label className="switch">
            <input id='tog' type="checkbox" checked={checked} onChange={handleToggle}></input>
            <span className="slider round"></span>
            </label>
        </div>
        <p className='toggleOption'>Current</p>
      </div>
      <ul id='peak' className='table peak' hidden={false}>{makeLeaderboardPeak}</ul>
      <ul id='curr' className='table current' hidden={true}>{makeLeaderboardCurrent}</ul>
    </div>
  )
}