import React from 'react'
import * as Realm from 'realm-web'
import { useNavigate } from 'react-router-dom'
import { Header } from './Header'
import { Footer } from './Footer'

export const Home = () => {
  const [tagTextBox, setTagTextBox] = React.useState('')
  const navigate = useNavigate()

  function verifyTag(tag) {
    const re = /^[A-Z0-9]{1,6}#[0-9]{1,3}$/
    return re.test(tag)
  }

  async function getRandomTag() {
    const app = new Realm.App({ id: 'slippielotracker-ksklh' });
    const credentials = Realm.Credentials.anonymous();
    var result = null
    try {
      const user = await app.logIn(credentials);
      result = await user.functions.getRandomTag()
      var biggest = null
      for (let i = 0; i < result.length; i++) {
        if (biggest === null || result[i].datapoints.length > biggest.datapoints.length) {
          biggest = result[i]
        }
      }
      setTagTextBox(biggest.tag)

    } catch (err) {
      console.error(err);
    }
  }

  const onGoClickHandler = () => {
    if (verifyTag(tagTextBox)) {
      navigate('/user/' + tagTextBox.toLowerCase().replace('#', '-'))
    }
    else {
      alert('Invalid Tag')
    }
  }

  const onTagChange = (e) => {
    const tagUpper = e.target.value.toUpperCase();
    setTagTextBox(tagUpper)
  }

  const onRandomClickHandler = () => {
    getRandomTag()
  }

  return (
    <div>
      <Header />
      <br />
      <br />
      <div className='inputs spacing'>
        <input className='tagInput' spellCheck='false' type='text' placeholder='user#123' maxLength='8' value={tagTextBox} onChange={onTagChange}></input>
        <br />
        <button className='inputButton' onClick={onGoClickHandler}>Go</button>
        <br />
        <button className='inputButton' onClick={onRandomClickHandler}>Random</button>
      </div>
      <Footer />
    </div>
  )
}